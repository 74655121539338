@include spacingUtils();

html {
  -webkit-tap-highlight-color: transparent;
}

html,
body {
  height: 100%;
}

#root {
  min-height: 100%;
}

body,
#app {
  font-family: var(--font-family-primary);
  background-color: var(--color-white);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #070a1c;
  color: white;
}

a {
  text-decoration: none;
}

hr {
  height: 1px;
  border: none;
  background-color: var(--color-grey-semi);
}

input[type='radio'],
input[type='range'] {
  -moz-appearance: none;
  -webkit-appearance: none;
}

input[type='checkbox'] {
  -moz-appearance: none;
  -webkit-appearance: none;
}

input[type='search']::-webkit-search-decoration,
input[type='search']::-webkit-search-cancel-button,
input[type='search']::-webkit-search-results-button,
input[type='search']::-webkit-search-results-decoration {
  -webkit-appearance: none;
}

input[type='file']::-webkit-file-upload-button {
  visibility: hidden;
}

input[type='file'] {
  position: absolute;
  inset: 0;
  min-height: 100%;
  min-width: 100%;
  max-height: 100%;
  max-width: 100%;
  color: transparent;
}

span {
  -webkit-appearance: none !important;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
textarea:-webkit-autofill:active,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus,
select:-webkit-autofill:active {
  transition: background-color 0s linear 3600s !important;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type='number'] {
  -moz-appearance: textfield;
}

select {
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
}
