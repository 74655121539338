:root {
  // Colors
  --color-white: #ffff;
  --color-black: #000f;
  --color-dark: #070a1c;
  --color-dark-accent: #0d153c;
  --color-primary: #3d0099;
  --color-love: #ff5427;
  --color-blue-light: #0ac0f8;

  // Icons sizes
  --icon-xxs: 0.75rem;
  --icon-xs: 1rem;
  --icon-sm: 1.25rem;
  --icon-md: 1.5rem;

  // Spacing
  --space-xxs: 0.25rem;
  --space-xs: 0.5rem;
  --space-sm: 0.75rem;
  --space-base: 1rem;
  --space-md: 1.5rem;
  --space-lg: 2rem;
  --space-xl: 4rem;
  --space-xxl: 6rem;
  --space-xxxl: 10rem;

  // Typography
  --text-root-size: 16px;

  --font-weight-thin: 200;
  --font-weight-light: 300;
  --font-weight-normal: 400;
  --font-weight-medium: 500;
  --font-weight-semibold: 600;
  --font-weight-bold: 700;
  --font-weight-extrabold: 800;
  --font-weight-ultra: 900;

  --text-xxs: 0.75rem;
  --text-xs: 0.8125rem;
  --text-sm: 0.875rem;
  --text-base: 1rem;
  --text-md: 1.25rem;
  --text-lg: 1.5rem;
  --text-xl: 2rem;
  --text-xxl: 3.5rem;

  --lineheight-xs: 1.125rem;
  --lineheight-sm: 1.1875rem;
  --lineheight-base: 1.4375rem;
  --lineheight-md: 1.625rem;
  --lineheight-lg: 1.9rem;
  --lineheight-xl: 2.5rem;
  --lineheight-xxl: 3.125rem;

  // Borders
  --rounded-xs: 6px;
  --rounded-sm: 0.25rem;
  --rounded-base: 0.75rem;
  --rounded-lg: 20px;
  --rounded-xl: 30px;
  --rounded-circle: 50%;

  // Components
  --header-height: 70px;

  // Fonts
  // --font-family-primary: 'Thin Pencil', -apple-system, BlinkMacSystemFont,
  //   'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
  //   'Droid Sans', 'Helvetica Neue', sans-serif;
  --font-family-primary: 'Onamura', -apple-system, BlinkMacSystemFont,
    'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
  --font-family-secondary: 'Onamura', -apple-system, BlinkMacSystemFont,
    'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
}
