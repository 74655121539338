.mint {
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: var(--space-xl);
  padding: var(--space-xxl) var(--space-xl);
}

.header {
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: var(--space-sm);
  max-width: 70ch;
}

.receipt {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: var(--space-lg) var(--space-xl);
  max-width: 600px;
  margin: 0 auto;
  padding: var(--space-lg);
  border: 1px solid var(--color-dark);
}

.infoTitle {
  text-decoration: underline 1px;
  text-underline-offset: 1px;
}

.info {
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: var(--space-xs);
}

.quote {
  grid-column: 1 / -1;
  line-height: 1.5;
}

video.media,
img.media {
  min-width: 100%;
  max-height: 400px;
  object-fit: contain;
}

.media {
  max-width: 100%;
  max-height: 50vh;
}

.footer {
  display: flex;
  column-gap: var(--space-base);
  margin: var(--space-lg) 0;
}
