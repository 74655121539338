.footer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  row-gap: var(--space-lg);
  padding: var(--space-base);

  @media screen and (min-width: 1000px) {
    padding: var(--space-xl);
  }

  a {
    color: var(--color-dark);
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
}
