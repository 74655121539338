.background {
  z-index: -10;
  position: fixed;
  inset: 0;
  height: 100vh;
  min-width: 100%;
  max-width: 100%;
  object-fit: cover;
  opacity: 0.95;
}
