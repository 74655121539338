.button {
  display: inline-flex;
  column-gap: var(--space-sm);
  align-items: center;
  padding: var(--space-sm) var(--space-md);
  line-height: var(--lineheight-md);
  letter-spacing: 0.25px;
  border: 1px solid transparent;
  border-radius: var(--rounded-sm);
  background-color: transparent;
  background-size: cover;
  color: var(--color-white);
  cursor: pointer;

  &:disabled {
    opacity: 0.25;
    cursor: not-allowed;
  }

  &-solid {
    background-color: var(--color-love);

    &:active {
      background-color: lighten(#ff5427, 10%);
    }
  }

  &-outline {
    color: var(--color-love);
    background-color: transparent;
    border-color: var(--color-love);
  }

  &Loading {
    animation: pulse 0.7s ease-out infinite direction fill-mode;
  }
}

// scaling... any units
$width: 30px;

.loader {
  position: relative;
  margin: 0 auto;
  width: $width;

  &:before {
    content: '';
    display: block;
    padding-top: 100%;
  }

  circle {
    stroke: var(--color-white);
  }
}

.circular {
  animation: rotate 2s linear infinite;
  height: 100%;
  transform-origin: center center;
  width: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
}

.path {
  stroke-dasharray: 1, 200;
  stroke-dashoffset: 0;
  animation: dash 1.5s ease-in-out infinite;
  stroke-linecap: round;
}

@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes dash {
  0% {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -35px;
  }
  100% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -124px;
  }
}
