.background {
  z-index: -1;
  position: absolute;
  padding: 0 var(--space-md);
  padding-bottom: var(--space-xxl);
  user-select: none;
  pointer-events: none;
}

.grid {
  object-fit: cover;
  max-width: 100%;
}
