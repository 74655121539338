@font-face {
  font-family: 'Onamura';
  font-weight: 200;
  src: url('../fonts/ONAMURA-Thin.otf');
}

@font-face {
  font-family: 'Onamura';
  font-weight: 300;
  src: url('../fonts/ONAMURA-Light.otf');
}

@font-face {
  font-family: 'Onamura';
  font-weight: 400;
  src: url('../fonts/ONAMURA-Normal.otf');
}

@font-face {
  font-family: 'Onamura';
  font-weight: 500;
  src: url('../fonts/ONAMURA-Medium.otf');
}

@font-face {
  font-family: 'Onamura';
  font-weight: 600;
  src: url('../fonts/ONAMURA-SemiBold.otf');
}

@font-face {
  font-family: 'Onamura';
  font-weight: 700;
  src: url('../fonts/ONAMURA-Bold.otf');
}

@font-face {
  font-family: 'Onamura';
  font-weight: 800;
  src: url('../fonts/ONAMURA-UltraBold.otf');
}

@font-face {
  font-family: 'Onamura';
  font-weight: 900;
  src: url('../fonts/ONAMURA-Black.otf');
}

@font-face {
  font-family: 'Thin Pencil';
  font-weight: 500;
  src: url('../fonts/Thin\ Pencil\ Handwriting.otf');
}
