.locked {
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: var(--space-xl);
  padding: var(--space-xxl) var(--space-xl);
}

.header {
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: var(--space-sm);
  max-width: 70ch;
}

.tx {
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: var(--space-sm);
}

.footer {
  display: flex;
  column-gap: var(--space-base);
  margin: var(--space-lg) 0;
}
