.concept {
  align-self: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  row-gap: var(--space-md);
  max-width: 80ch;
  text-align: center;
}

.perksList {
  display: flex;
  flex-direction: column;
  row-gap: var(--space-xl);
  margin: var(--space-lg) 0;

  @media screen and (min-width: 1000px) {
    flex-direction: row;
    column-gap: var(--space-md);
    margin: var(--space-xl) 0;
  }
}

.perksItem {
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: var(--space-base);

  @media screen and (min-width: 1000px) {
    flex: 1 0 33%;
  }
}
