.wrapper {
  z-index: 1;
  display: flex;
  align-items: center;
}

.logoFull {
  width: 10rem;
}

.logoPicto {
  width: 4rem;
}
