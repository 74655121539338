.steps {
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: var(--space-md);
  max-width: 100ch;
  padding: var(--space-xl) var(--space-md);
  text-align: center;

  @media screen and (min-width: 1000px) {
    padding: var(--space-xl);
  }
}

.header {
  display: flex;
  flex-direction: column;
  row-gap: var(--space-lg);
  margin-bottom: var(--space-xl);
  max-width: 70ch;
}

.stepsList {
  display: flex;
  flex-direction: column;
  gap: var(--space-md);
  margin: var(--space-lg) 0;

  @media screen and (min-width: 1000px) {
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
    margin: var(--space-xl) 0;
  }
}

.stepsItem {
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: var(--space-base);

  @media screen and (min-width: 1000px) {
    flex: 1 0 33%;
  }

  > h3 {
    border: 1px solid var(--color-dark);
    padding: var(--space-md);
    border-radius: var(--rounded-circle);
  }
}
