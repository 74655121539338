.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.uploadWrapper {
  display: flex;
  flex-direction: column;
  border: 2px dotted var(--color-love);
  color: var(--color-love);

  * {
    cursor: pointer;
  }

  &Active {
    border-color: transparent;
    background-color: var(--color-love);
    color: var(--color-white);
  }
}

.input {
  display: flex;
  justify-content: center;
  flex-grow: 1;
  padding: var(--space-base) var(--space-lg);
  font-weight: var(--font-weight-semibold);
  font-size: var(--text-md);
}
