.main {
  display: grid;

  &Landing {
    grid-template-rows: calc(95vh - var(--header-height)) max-content repeat(
        4,
        max-content
      );
    grid-row-gap: var(--space-xxxl);
    justify-items: center;
  }

  &Lock {
    grid-template-rows: minmax(100vh, auto);
  }
}
