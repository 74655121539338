.plants {
  z-index: -1;
  position: fixed;
  inset: 0;
  height: 100%;
  min-width: 100%;
  max-width: 100%;
  object-fit: cover;
  opacity: 0.95;
  pointer-events: none;
  user-select: none;
}

.loveLogo {
  position: fixed;
  width: 35vw;
  top: 0;
  right: 0;
  object-fit: cover;
  pointer-events: none;
  user-select: none;
}

.locksLogo {
  position: fixed;
  width: 50vw;
  bottom: 0;
  left: 0;
  object-fit: cover;
  pointer-events: none;
  user-select: none;
}
