.file {
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: var(--space-xl);
  padding: var(--space-xl);
}

.header {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 50%;
}

.filePreview {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 200px;
  max-height: 500px;

  > * {
    max-height: 100%;
    max-width: 100%;
    object-fit: cover;
  }
}
