$variants: (
  'headline': var(--text-xxl),
  'title': var(--text-xl),
  'subtitle': var(--text-lg),
  'lg': var(--text-md),
  'md': var(--text-base),
  'sm': var(--text-sm),
  'xs': var(--text-xs),
);

$families: (
  'primary': var(--font-family-primary),
  'secondary': var(--font-family-secondary),
);

$weights: (
  'thin': var(--font-weight-thin),
  'light': var(--font-weight-light),
  'normal': var(--font-weight-normal),
  'medium': var(--font-weight-medium),
  'semibold': var(--font-weight-semibold),
  'bold': var(--font-weight-bold),
  'extrabold': var(--font-weight-extrabold),
  'ultra': var(--font-weight-ultra),
);

$lineheights: (
  'xxl': var(--lineheight-xxl),
  'xl': var(--lineheight-xl),
  'lg': var(--lineheight-lg),
  'md': var(--lineheight-md),
  'base': var(--lineheight-base),
  'sm': var(--lineheight-sm),
  'xs': var(--lineheight-xs),
);

$colors: (
  'white': white,
  'dark': var(--color-dark),
  'love': var(--color-love),
  'blue-light': var(--color-blue-light),
  'gray': gray,
);

$alignments: (
  'left': left,
  'center': center,
  'right': right,
);

@each $variant-key, $variant-value in $variants {
  .variant-#{$variant-key} {
    font-size: $variant-value;
  }
}

@each $weight-key, $weight-value in $weights {
  .weight-#{$weight-key} {
    font-weight: $weight-value;
  }
}

@each $lineheight-key, $lineheight-value in $lineheights {
  .lineheight-#{$lineheight-key} {
    line-height: $lineheight-value;
  }
}

@each $color-key, $color-value in $colors {
  .color-#{$color-key} {
    color: $color-value;
  }
}

@each $alignment-key, $alignment-value in $alignments {
  .align-#{$alignment-key} {
    text-align: $alignment-value;
  }
}

@each $family-key, $family-value in $families {
  .family-#{$family-key} {
    font-family: $family-value;
  }
}
