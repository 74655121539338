// Spacing utils classes:
// Global classes e.g: p-md ==> padding: var(--space-md)
// Side classes e.g: pt-xs ==> padding-top: var(--space-xs)
// Axis classes e.g: mx-base ==> margin-left, margin-right: var(--space-base)
@mixin spacingUtils() {
  $sides: (
    't': top,
    'r': right,
    'b': bottom,
    'l': left,
  );

  $scales: (
    'auto': auto,
    '0': 0,
    'xxs': var(--space-xxs),
    'xs': var(--space-xs),
    'sm': var(--space-sm),
    'base': var(--space-base),
    'md': var(--space-md),
    'lg': var(--space-lg),
    'xl': var(--space-xl),
    'xxl': var(--space-xxl),
  );

  @each $scale-key, $scale-value in $scales {
    .m-#{$scale-key} {
      margin: $scale-value !important;
    }

    .mx-#{$scale-key} {
      margin-left: $scale-value !important;
      margin-right: $scale-value !important;
    }

    .my-#{$scale-key} {
      margin-top: $scale-value !important;
      margin-bottom: $scale-value !important;
    }

    .p-#{$scale-key} {
      padding: $scale-value !important;
    }

    .px-#{$scale-key} {
      padding-left: $scale-value !important;
      padding-right: $scale-value !important;
    }

    .py-#{$scale-key} {
      padding-top: $scale-value !important;
      padding-bottom: $scale-value !important;
    }
  }

  @each $side-key, $side-value in $sides {
    @each $scale-key, $scale-value in $scales {
      .m#{$side-key}-#{$scale-key} {
        margin-#{$side-value}: $scale-value !important;
      }

      .p#{$side-key}-#{$scale-key} {
        padding-#{$side-value}: $scale-value !important;
      }
    }
  }
}
