.grid {
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: var(--space-md);
  max-width: 100ch;
  padding: var(--space-xl) var(--space-md);
  text-align: center;

  @media screen and (min-width: 1000px) {
    padding: var(--space-xl);
  }
}
