.quote {
  display: flex;
  flex-direction: column;
  row-gap: var(--space-xl);
  padding: var(--space-xl);
  align-items: center;
}

.header {
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: var(--space-sm);
  max-width: 50%;
}

.heading {
  display: inline-flex;
  align-items: baseline;
  flex-wrap: wrap;

  span {
    flex: 0 1 auto;
  }
}

.quoteInputWrapper {
  display: flex;
  flex-direction: column;
  row-gap: var(--space-base);
  min-width: 50%;
  max-width: 50%;
}

.quoteInput {
  padding: var(--space-base);
  font-size: var(--text-lg);
  line-height: var(--lineheight-xl);
  border: 1px solid var(--color-love);
  border-radius: var(--rounded-sm);
  color: var(--color-love);
  resize: none;
}
