.hero {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  row-gap: var(--space-xl);
  padding: var(--space-md);
}

.form {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: var(--space-lg) var(--space-base);
  justify-items: center;

  button {
    grid-column: 1 / -1;
  }
}
