.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: var(--space-xs);
}

.input {
  padding: var(--space-xs) var(--space-md);
  font-size: var(--text-md);
  font-weight: var(--font-weight-bold);
  line-height: var(--lineheight-xl);
  text-align: center;
  border: 1px solid var(--color-love);
  border-radius: var(--rounded-xs);
  color: var(--color-love);

  &::placeholder {
    font-size: var(--text-xxs);
    font-style: italic;
    color: lighten(#ec0e6c, 25%);
  }
}
