.toaster {
  position: fixed;
  inset: 0;
  top: auto;
  padding: var(--space-base);
  background-color: red;
  transform: translateY(100%);
  transition: transform 0.25s cubic-bezier(0.215, 0.61, 0.355, 1);

  &Active {
    transform: translateY(0);
  }
}

.toastList {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.toast {
  display: flex;
}
